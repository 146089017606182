header {
  height: 0.85rem /* 164/192 */;
  padding: 0 1.88rem /* 360/192 */;
  //   opacity: 0.06;
  background: rgba($color: #000000, $alpha: 0.06);
  nav {
    .top {
      height: 0.23rem /* 44/192 */;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666666;
      font-size: 0.07rem /* 14/192 */;
      .left {
        display: flex;
        cursor: pointer;
        div:first-child {
          margin-right: 0.21rem /* 40/192 */;
          color: #000000;
          span {
            color: #b3b3b3;
          }
        }
      }
      .right {
        display: flex;
        a:first-child {
          margin-right: 0.21rem /* 40/192 */;
        }
      }
    }
    .op {
      height: 0.63rem /* 120/192 */;
      display: flex;
      align-items: center;
      .logo {
        display: flex;
        align-items: center;
        margin-right: 0.21rem /* 40/192 */;

        img {
          width: 1.06rem /* 203/192 */;
          height: 0.25rem /* 48/192 */;
          margin-right: 0.06rem /* 11/192 */;
        }
        // div {
        //   font-size: 0.19rem /* 36/192 */;
        //   font-weight: 500;
        //   color: #333333;
        // }
      }
      .status {
        padding: 0.03rem /* 5/192 */;
        background: #3582f0;
        color: #fff;
        font-size: 0.07rem /* 14/192 */;
        border-radius: 0.02rem /* 4/192 */;
        margin-right: 0.26rem /* 50/192 */;
        white-space: nowrap;
      }
      .search {
        display: flex;
        align-items: center;
        width: 2.6rem /* 500/192 */;
        height: 0.23rem /* 44/192 */;
        background: #ffffff;
        box-shadow: 0px 0.03rem /* 6/192 */ 0.06rem /* 12/192 */ 0px
          rgba(108, 135, 173, 0.3);
        border-radius: 0.16rem /* 30/192 */;
        .search_icon {
          height: 0.08rem /* 16/192 */;
          width: 0.08rem /* 16/192 */;
          margin-right: 0.08rem /* 16/192 */;
          margin-left: 0.07rem /* 14/192 */;
        }
        input {
          height: 0.21rem /* 40/192 */;
          width: calc(100% - 0.66rem /* 126/192 */);
          font-size: 0.07rem /* 14/192 */;

          border: none;
          outline: #00ff00 thick;
        }
        .search_button {
          margin-left: auto;
          height: 100%;
          width: 0.42rem /* 80/192 */;
          background: #3582f0;
          color: #fff;
          font-size: 0.1rem /* 20/192 */;
          border: none;
          border-radius: 0.11rem /* 22/192 */;
        }
      }
      .search_active {
        border: 1px solid #3582f0;
      }
      .qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 0.84rem /* 161/192 */;

        img {
          width: 0.17rem /* 32/192 */;
          height: 0.17rem /* 32/192 */;
        }
        div {
          font-size: 0.06rem /* 12/192 */;
          color: #b3b3b3;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .login {
        width: 0.31rem /* 60/192 */;
        height: 0.19rem /* 36/192 */;
        border-radius: 0.02rem /* 4/192 */;
        margin-left: auto;
        .login_button {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #3582f0;
          color: #fff;
          font-size: 0.07rem /* 14/192 */;
          
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }
}
