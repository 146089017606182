.car_pictures {
  background-color: #fff;
  padding: 0.1rem /* 20/192 */ 0.26rem /* 50/192 */ 0.26rem /* 50/192 */ 0.26rem
    /* 50/192 */;
  .car_pictures_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .19rem /* 36/192 */;
    .title {
      font-size: 0.16rem /* 30/192 */;
      font-weight: 500;
      color: #1a1a1a;
    }
    .move {
      font-size: 0.1rem /* 20/192 */;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
  }
  .car_pictures_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .car_pictures_big {
      width: 2.76rem /* 530/192 */;
      height: 2.37rem /* 455/192 */;
      background: rgba(0, 0, 0, 0);
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      .car_pictures_big_title {
        position: absolute;
        top: 0.1rem /* 20/192 */;
        left: 0.13rem /* 24/192 */;
        font-size: 0.16rem /* 30/192 */;
        font-weight: 500;
        color: #ffffff;
      }
      .car_pictures_big_text {
        position: absolute;
        top: 0.31rem /* 60/192 */;
        left: 0.13rem /* 24/192 */;
        font-size: 0.1rem /* 20/192 */;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 0px 0.01rem /* 2/192 */ 0.02rem /* 4/192 */ 0px
          rgba(0, 0, 0, 0.16);
      }
      .car_pictures_big_tag {
        position: absolute;
        bottom: 0.1rem /* 20/192 */;
        width: 100%;
        text-align: center;
        font-size: 0.1rem /* 20/192 */;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .car_pictures_list {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      padding-left: .08rem /* 15/192 */;
      height: 2.37rem /* 455/192 */;
      width: calc(100% - 2.76rem );
      .car_pictures_item {
        position: relative;
        width: calc(50% - .04rem) /* 273/192 */;
        height: 1.15rem /* 220/192 */;
        border-radius: 0px 0px 0.02rem /* 4/192 */ 0.02rem /* 4/192 */;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .car_pictures_item_tag {
          position: absolute;
          bottom: 0.1rem /* 20/192 */;
          width: 100%;
          text-align: center;
          font-size: 0.1rem /* 20/192 */;
          font-weight: 500;
          color: #ffffff;
        }
      }
     
    }
  }
}
