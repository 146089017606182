.hot_brand {
  padding: 0 0.16rem /* 30/192 */ 0.26rem /* 50/192 */ 0.16rem /* 30/192 */;
  background-color: #fff;
  .hot_brand_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 0.16rem /* 30/192 */;
      font-weight: 500;
      color: #1a1a1a;
    }
    .move {
      font-size: 0.1rem /* 20/192 */;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
  }

  .hot_brand_list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.13rem /* 24/192 */;

    .hot_brand_item {
      width: calc(25% - 0.06rem);
      //   height: 1.25rem /* 240/192 */;
      background: #ffffff;
      border-radius: 0.05rem /* 10/192 */;
      box-shadow: 0px 0.02rem /* 3/192 */ 0.03rem /* 6/192 */ 0px
        rgba(0, 0, 0, 0.16);
      position: relative;
      margin-right: 0.08rem /* 15/192 */;
      margin-bottom: 0.16rem /* 30/192 */;
      .brand_logo {
        position: absolute;
        top: 0.11rem /* 22/192 */;
        left: 0.15rem /* 29/192 */;
        width: 0.32rem /* 61/192 */;
        text-align: center;
        img {
          width: 0.32rem /* 61/192 */;
          height: 0.32rem /* 61/192 */;
          background: rgba(0, 0, 0, 0);
        }
        span {
          font-size: 0.1rem /* 20/192 */;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .hot_brand_car_img {
        height: 0.71rem /* 137/192 */;
        width: 100%;
        cursor: pointer;
      }
      .hot_brand_car_class_list {
        padding: 0.16rem /* 30/192 */ 0.1rem /* 20/192 */ 0.02rem /* 4/192 */
          0.1rem /* 20/192 */;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        overflow: hidden;
        
        .hot_brand_car_class_item {
          font-size: 0.07rem /* 14/192 */;
          font-weight: 400;
          color: #333333;
          padding-bottom: 0.1rem /* 20/192 */;
          padding-right: 0.08rem /* 15/192 */;
          cursor: pointer;
        }
        .hot_brand_car_class_move {
          font-size: 0.07rem /* 14/192 */;
          font-weight: 400;
          color: #b3b3b3;
          margin-left: auto;
          cursor: pointer;
          
        }
      }
    }

    :nth-child(4n) {
      margin-right: 0;
    }
  }
}
