.car_detail {
  background-color: #fff;
  padding: 0.17rem /* 33/192 */ 0.1rem /* 20/192 */ 0 0.1rem /* 20/192 */;
  border-radius: 0.21rem /* 40/192 */;
  .car_detail_nav {
    font-size: 0.1rem /* 20/192 */;
    font-weight: 400;
    color: #666666;
    padding-bottom: 0.14rem /* 27/192 */;
    :first-child:hover {
      color: #3582f0;
      cursor: pointer;
    }
  }
  .car_detail_content {
    display: flex;
    height: calc(100% - 0.14rem);
    position: relative;

    .choice_content {
      .car_info_content {
        display: flex;
        justify-content: space-between;
        .car_img {
          width: 3.13rem /* 600/192 */;
          height: 2.08rem /* 400/192 */;
        }
        .car_info {
          padding-left: 0.21rem /* 40/192 */;
          width: 2.73rem /* 524/192 */;
          .price {
            font-size: 0.1rem /* 20/192 */;
            color: #999999;
            span {
              font-size: 0.15rem /* 28/192 */;
              font-weight: 500;
              color: #ff4646;
            }
          }
          .charge_list {
            margin-top: 0.1rem /* 20/192 */;
            padding: 0.08rem /* 15/192 */ 0.1rem /* 20/192 */;
            background-color: #e6e6e6;
            border-radius: 0.04rem /* 8/192 */;
            .item {
              display: flex;
              justify-content: space-between;
              font-size: 0.08rem /* 16/192 */;
              color: #1a1a1a;
            }
          }
          .config {
            margin-top: 0.21rem /* 40/192 */;
            font-size: 0.1rem /* 20/192 */;
            color: #999999;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .item {
              width: 50%;
              
              
              span {
                color: #ccc;
              }
            }
          }
          .rush_purchase {
            margin-top: 0.3rem /* 57/192 */;
            width: 0.83rem /* 160/192 */;
            height: 0.31rem /* 60/192 */;
            background: #3582f0;
            color: #fff;
            line-height: 0.31rem;
            text-align: center;
            font-size: 0.15rem /* 28/192 */;
            font-weight: 500;
            color: #ffffff;
          }
          .rush_purchase:hover{
            background: rgb(5, 96, 223);
            cursor: pointer;
          }
        }
      }
      .price_list {
        .title {
          font-size: 0.15rem /* 28/192 */;
          color: #3582f0;
        }
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //   height: 0.21rem /* 40/192 */;
          
          border-top: 1px solid #ccc;
          padding: 0.1rem /* 20/192 */ 0;
          margin-top: 0.1rem /* 20/192 */;
          div{
            width: 25%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // text-align: center;
          }
          .item_name {
            font-size: 0.1rem /* 20/192 */;
            color: #333333;
          }
          .item_config {
            font-size: 0.08rem /* 16/192 */;
            color: #999999;
          }
          .item_price {
            font-size: 0.08rem /* 16/192 */;
            color: #ff4646;
            text-align: center;
          }
          .item_button {
            width: 0.42rem /* 80/192 */;
            height: 0.21rem /* 40/192 */;
            background: #fe8822;
            color: #fff;
            font-size: 0.1rem /* 20/192 */;
            color: #ffffff;
            line-height: 0.21rem;
            text-align: center;
            cursor: pointer;
            
          }
          .item_button:hover{
            background: #ff7700;
          }
        }
      }
    }
  }
}
