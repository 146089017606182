.today_focus_detail {
  background-color: #fff;
  border-radius: 0.21rem /* 40/192 */;
  padding: .16rem /* 30/192 */;
  .focus_detail_title {
    font-size: 0.15rem /* 28/192 */;
    font-weight: 500;
    text-align: center;
    color: #1a1a1a;
    padding: 0.16rem /* 30/192 */ 0;
  }
  .time_writer {
    display: flex;
    justify-content: center;
    padding: 0.18rem /* 35/192 */ 0;
  }
  .content {
    p {
      font-size: 0.1rem /* 20/192 */;
      font-weight: 500;
      color: #1a1a1a;
    }
    .img_content{
        overflow: hidden;
        width: 100%;
        height: 3.5rem;
        img{
            width: 100%;
            
        }
    }
    
  }
}
