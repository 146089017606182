.today_focus {
  padding: 0.28rem /* 54/192 */ 0.16rem /* 30/192 */ 0.26rem /* 50/192 */
    0.16rem;
  background-color: #fff;
  .today_focus_nav {
    margin-bottom: 0.14rem /* 27/192 */;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 0.16rem /* 30/192 */;
      font-weight: 500;
      color: #1a1a1a;
    }
    .tag {
      margin-right: auto;
      margin-left: 0.05rem /* 10/192 */;
      padding: 0.02rem /* 4/192 */ 0.03rem /* 6/192 */;
      font-size: 0.09rem /* 18/192 */;
      font-weight: 500;
      color: #ffffff;
    }
    .move {
      font-size: 0.1rem /* 20/192 */;
      color: #666666;
      cursor: pointer;
    }
  }
  .today_focus_content {
    display: flex;
    img {
      width: 3.39rem /* 650/192 */;
      height: 2.08rem /* 400/192 */;
      border-radius: 0.04rem /* 8/192 */;
      cursor: pointer;
    }
    .today_focus_content_tag_list {
      padding-left: 0.1rem /* 20/192 */;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      color: #333333;
      height: 2.08rem /* 400/192 */;
      overflow: hidden;
      text-overflow: ellipsis;
      .tag_item:first-child {
        color: #3582f0;
      }
      .tag_item {
        font-size: 0.08rem /* 16/192 */;
        padding-right: 0.11rem /* 21/192 */;
        cursor: pointer;
        padding-bottom: 0.08rem /* 16/192 */;
        font-weight: 400;
      }
      :hover {
        color: #3582f0;
      }
    }
  }
}
