.contact_us {
  background-color: #fff;
  padding: 0.1rem /* 20/192 */ 0.26rem /* 50/192 */ .31rem /* 60/192 */ 0.26rem
    /* 50/192 */;
    border-radius: 0 0 .21rem /* 40/192 */ .21rem /* 40/192 */;
  .contact_us_nav {
    display: flex;
    align-items: center;
    padding-bottom: 0.19rem /* 36/192 */;
    .title {
      font-size: 0.16rem /* 30/192 */;
      font-weight: 500;
      color: #1a1a1a;
      position: relative;
      padding-right: 0.05rem /* 10/192 */;
    }
    .tag {
      font-size: 0.09rem /* 18/192 */;
      font-weight: 400;
      color: #333333;
      padding-left: 0.05rem /* 10/192 */;
    }
    ::after {
      content: "";
      position: absolute;
      right: 0;
      top: calc(50% - 0.07rem);
      height: 0.15rem /* 28/192 */;
      width: 1px;
      background-color: #ccc;
    }
  }
  .contact_us_content {
    display: flex;
    justify-content: space-between;
    height: 1.77rem /* 340/192 */;
    .contact_us_content_text {
      width: 2.08rem /* 400/192 */;
      .email,
      .address {
        display: flex;
        font-size: 0.1rem /* 20/192 */;
        font-weight: 400;
        color: #1a1a1a;
        padding-bottom: .21rem /* 40/192 */;
        img {
          width: .13rem /* 25/192 */;
          height: .13rem /* 25/192 */;
          padding-right: .05rem /* 9/192 */;
        }
        :first-child {
            font-weight: 500;
            padding-bottom: .16rem /* 30/192 */;
        }
      }
    }
    .contact_us_content_location{
        width: calc(100% - 2.08rem);
        height: 100%;
        border-radius: .1rem /* 20/192 */;
        overflow: hidden;
    }
  }
}
