.menu {
  .menu_nav {
    height: 0.42rem /* 80/192 */;
    background: #3582f0;
    color: #ffffff;
    padding: 0 0.16rem /* 30/192 */;
    border-radius: 0.21rem /* 40/192 */ 0.21rem /* 40/192 */ 0px 0px;
    display: flex;
    align-items: center;
    font-weight: 500;

    .title {
      font-size: 0.16rem /* 30/192 */;
      margin-right: 0.36rem /* 70/192 */;
    }
    .nav_item {
      font-size: 0.1rem /* 20/192 */;
      color: #ffffff;
      margin-right: 0.47rem /* 90/192 */;
      cursor: pointer;
    }
  }

  .menu_car_class {
    background-color: #fff;

    .menu_car_class_item {
      height: 0.42rem /* 80/192 */;

      display: flex;
      align-items: center;
      
      .car_class_name {
        width: 0.57rem /* 110/192 */;
        padding-left: 0.16rem /* 30/192 */;
        padding-right: 0.08rem /* 15/192 */;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.1rem /* 20/192 */;
        div {
          color: #ccc;
        }
        .name {
          font-weight: 500;
          color: #333333;
        }
        ::after {
          content: "";
          position: absolute;
          right: 0;
          height: 0.16rem /* 30/192 */;
          width: 0.01rem /* 2/192 */;
          background-color: #ccc;
        }
      }
      .car_class_name_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 0.73rem /* 140/192 */;
        cursor: pointer;
        .title {
          font-size: 0.08rem /* 16/192 */;
          color: #333333;
          font-weight: 400;
          margin-bottom: 0.05rem /* 10/192 */;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .op {
          width: 80%;
          display: flex;
          justify-content: space-between;
          font-size: 0.06rem /* 12/192 */;
          color: #666666;
          div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        :hover{
          color: #3582f0;
        }
      }
    }
  }
}
