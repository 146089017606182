.car_selection {
  background-color: #fff;
  padding: 0.17rem /* 33/192 */ 0.1rem /* 20/192 */ 0 0.1rem /* 20/192 */;
  border-radius: 0.21rem /* 40/192 */;
  .car_selection_nav {
    font-size: 0.1rem /* 20/192 */;
    font-weight: 400;
    color: #666666;
    padding-bottom: 0.14rem /* 27/192 */;
    :first-child:hover{
      color: #3582f0;
      cursor: pointer;
    }
    
  }
  .car_selection_content {
    display: flex;
    height: calc(100% - 0.14rem);
    position: relative;
    .letter {
      width: 0.16rem /* 30/192 */;
      background: #6dc6f2;
      display: flex;
      flex-direction: column;
      // overflow: hidden;
      .letter_item {
        text-align: center;
        color: #fff;
        font-size: 0.08rem /* 16/192 */;
        font-weight: 500;
        cursor: pointer;
      }
      .letter_item:hover {
        color: #3582f0;
      }
    }
    .scrllow_view {
      position: absolute;
      left: .16rem /* 30/192 */;
      width: 1.15rem /* 220/192 */;
      
      height: 100%;
      border: 1px solid #e6e6e6;
      overflow-y: auto;
      overflow-x: hidden;
      .scrllow_view_item {
        .scrllow_view_letter {
          width: 1.06rem /* 204/192 */;
          height: 0.19rem /* 36/192 */;
          line-height: 0.19rem;
          padding-left: 0.05rem /* 10/192 */;
          background: #f5f5f5;

          font-size: 0.1rem /* 20/192 */;
          font-weight: 500;
          color: #333333;
        }
        .scrllow_view_letter_brand {
          width: 1.06rem /* 204/192 */;
          height: 0.19rem /* 36/192 */;
          line-height: 0.19rem;
          padding-left: 0.05rem /* 10/192 */;
          font-size: 0.07rem /* 14/192 */;
          color: #333333;
          border-top: 1px solid #f5f5f5;
          cursor: pointer;
        }
        .scrllow_view_letter_brand:hover {
          color: #fff;
          background-color: #3582f0;
        }
      }
    }
    .scrllow_view::-webkit-scrollbar {
      width: 0.04rem /* 8/192 */;
      background: #fafafa;
      border: 1px solid #e6e6e6;
    }
    .scrllow_view::-webkit-scrollbar-thumb {
      border-radius: 0.05rem /* 10/192 */;
      -webkit-box-shadow: inset 0 0 0.03rem /* 5/192 */ rgba(70, 69, 69, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .choice_content {
      padding-left: 1.3rem /* 250/192 */;
      
      .title {
        font-size: 0.15rem /* 28/192 */;
        color: #3582f0;
        padding-bottom: 0.1rem /* 20/192 */;
      }
      .condition_op {
        // height: 0.29rem /* 56/192 */;
        width: 4.64rem /* 890/192 */;
        background: #f5f5f5;
        border: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        padding: 0.07rem /* 13/192 */ 0.08rem /* 15/192 */;
        .condition_op_title {
          color: #999999;
          font-size: 0.07rem /* 14/192 */;
        }
        .condition_item {
          font-size: 0.07rem /* 14/192 */;
          padding: 0.04rem /* 8/192 */ 0.05rem /* 10/192 */;
          line-height: 0.16rem;
          background: #ffffff;
          border: 1px solid #3582f0;
          color: #333333;
          margin-left: 0.05rem /* 10/192 */;
        }
        .remove_op {
          font-size: 0.07rem /* 14/192 */;
          color: #333333;
          padding-left: 0.13rem /* 24/192 */;
          cursor: pointer;
        }
      }
      .price_op {
        color: #999999;
        width: 4.64rem /* 890/192 */;
        background: #ffffff;
        padding: 0.07rem /* 13/192 */ 0.08rem /* 15/192 */;
        span {
          font-size: 0.07rem /* 14/192 */;
        }
      }
      .car_selection_car_list {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        width: 4.64rem /* 890/192 */;
        overflow-y: auto;
        height: 4.17rem /* 800/192 */;
        
        .car_selection_car_item {
          width: 1.04rem /* 200/192 */;
          height: 1.04rem /* 200/192 */;
          padding: 0.05rem /* 9/192 */;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0.1rem /* 20/192 */;
          margin-right: .08rem /* 15/192 */;
          img {
            width: 0.96rem /* 184/192 */;
            // height: 0.58rem /* 111/192 */;
          }
          .car_item_title {
            font-size: 0.08rem /* 16/192 */;
            color: #333333;
          }
          .car_item_price {
            font-size: 0.07rem /* 14/192 */;
            color: #ff4646;
          }
          .car_item_op {
            font-size: 0.06rem /* 12/192 */;
            color: #999999;
          }
        }
        :nth-child(4n){
          margin-right: 0;
        }
      }

      .car_selection_car_list::-webkit-scrollbar {
        width: 0.04rem /* 8/192 */;
        background: #fafafa;
        border: 1px solid #e6e6e6;
      }
      .car_selection_car_list::-webkit-scrollbar-thumb {
        border-radius: 0.05rem /* 10/192 */;
        -webkit-box-shadow: inset 0 0 0.03rem /* 5/192 */ rgba(70, 69, 69, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  
  
}
