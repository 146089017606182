footer {
  // position: absolute;
  // bottom: 0;
  margin-top: auto;
  padding: 0 1.88rem /* 360/192 */;
  // width: calc(100% - 1.88rem * 2);
  height: 1.69rem /* 325/192 */;
  background: #30353f;
  .top {
    padding-top: 0.36rem /* 70/192 */;
    display: flex;
    justify-content: space-between;
    .qrcode {
      display: flex;
      .item {
        margin-right: 0.31rem /* 60/192 */;
        text-align: center;
        img {
          width: 0.63rem /* 120/192 */;
          height: 0.63rem /* 120/192 */;
          background: #ffffff;
          border-radius: 0.02rem /* 4/192 */;
          margin-bottom: 0.1rem /* 20/192 */;
        }
        div {
          font-size: 0.1rem /* 20/192 */;
          color: #b3b3b3;
        }
      }
    }
    .text {
      display: flex;
      font-size: 0.16rem /* 30/192 */;
      font-weight: 400;
      text-align: right;
      color: #ffffff;
      div:first-child {
        margin-left: 0.52rem /* 100/192 */;
      }
      .content {
        font-size: 0.08rem /* 16/192 */;
        color: #ffffff;
        margin-top: 0.16rem /* 30/192 */;
      }
    }
  }
  .bottom {
    margin-top: .1rem ;
    font-size: 0.08rem /* 16/192 */;
    color: #b3b3b3;
    text-align: center;
  }
}
